@keyframes scaleIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.scaleIn {
    opacity: 0;
    animation-name: scaleIn;
    -webkit-animation-name: scaleIn;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slideInFromRight {
    opacity: 0;
    animation-name: slideInFromRight;
    -webkit-animation-name: slideInFromRight;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: both
}