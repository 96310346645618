.digitInput {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  width: 0.6em;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  transition: all 0.4s;
  margin-left: 5px;
  animation: fadeInAnimation ease 3s;
  border-width: 2px;
}
.digitInput:focus {
  outline-width: 0;
  border-color: white;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
