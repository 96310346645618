@keyframes fadeIn {
  from {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both
}

.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both
}
