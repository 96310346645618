@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@500&display=swap");

* {
  font-family: "Catamaran", sans-serif;
}

* {
  scrollbar-width: none;
  overflow: auto;
}

*::-webkit-scrollbar {
  display: none;
}

.title {
  font-size: xx-large;
  font-weight: 600;
  color: white;
  animation: fadeInAnimation ease 3s;
}

.subtitle {
  font-size: xx-large;
  font-weight: 500;
  color: white;
  animation: fadeInAnimation ease 3s;
}

.header {
  font-size: x-large;
  font-weight: 500;
  color: white;
  animation: fadeInAnimation ease 3s;
}

.userQuery {
  background: transparent;
  border: solid;
  border-color: white;
  border-width: 2px;
  min-width: 20%;
  max-width: 70%;
  min-height: 30px;
  text-align: center;
  color: white;
  font-size: large;
  font-weight: 500;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  padding: 7px 10px 0px 10px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.userQuery:focus {
  outline: none;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}

.basicButton {
  width: auto;
  height: auto;
  font-size: large;
  font-weight: 600;
  padding: 10px 35px;
  background-color: transparent;
  border-color: white;
  color: white;
  border-radius: 30px;
  border-style: solid;
  transition: background-color 0.7s, color 0.7s;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.basicButton:hover {
  background-color: white;
  color: black;
}

.submitButton {
  width: auto;
  height: auto;
  font-size: large;
  font-weight: 600;
  padding: 10px 35px;
  background-color: transparent;
  border-color: white;
  border-width: 2px;
  color: white;
  border-radius: 30px;
  border-style: solid;
  margin-top: 3vh;
  transition: background-color 1s, color 1s;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.submitButton:hover {
  background-color: white;
  color: black;
}

.startButton {
  width: auto;
  height: auto;
  font-size: x-large;
  font-weight: 600;
  padding: 20px 60px;
  border-color: white;
  border-width: 2px;
  color: white;
  border-radius: 40px;
  transition: background-color 1s, color 1s;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.startButton:hover {
  background-color: white;
  color: black;
}

.loader {
  border: 0 solid transparent;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}

.loader::before,
.loader::after {
  content: "";
  border: 7px solid #ccc;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  animation: loader 2s linear infinite;
  opacity: 0;
}

.loader::before {
  animation-delay: 1s;
}

@keyframes loader {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes delayedFadeInAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
